<template>
    <div id="checkout">
        <div class="custom-container">
            <div class="row mx-0">
                <div class="col-md-7">
                    <div class="main-info">
                        <nav aria-label="breadcrumb" style="padding: unset!important;">
                            <ol class="breadcrumb">
                                <li v-if="mode == 'default'" class="breadcrumb-item"><router-link to="/store/cart">Cart</router-link></li>
                                <li v-if="mode == 'e-prescription'" class="breadcrumb-item"><router-link :to="`/store/cart?mode=e-prescription&e-prescription=${$route.query['e-prescription']}`">Cart</router-link></li>
                                <li class="breadcrumb-item active" aria-current="page">Information & Payment</li>
                            </ol>
                        </nav>

                        <div class="mt-4">
                            <h5 class=" d-inline">Contact information</h5>
                            <div class="float-md-right small-info" v-if="guestData.isGuest">
                                <small>Already have an account? <router-link to="/auth/login">Log in</router-link></small>
                            </div>
                            <div class="mt-3" v-if="currentCustomer">
                                <div class="img-profile">
                                    <img src="../../../assets/avatar01.jpg" alt="">
                                </div>
                                <div class="info-profile">
                                    <p>{{ currentCustomer.user.username }} ({{ currentCustomer.user.email }})</p>
                                    <a href="#" @click="signOut" class="nav-link">Log Out</a>
                                </div>
                            </div>
                            <div class="mt-2" v-else>
                                <div class="row">
                                    <div class="col">
                                        <input type="email" class="form-control" placeholder="Email" v-model="guestData.email" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4" v-if="isPrescriptionNeeded">
                            <h5>Required Informations</h5>
                            <div class="mt-2 mb-3">
                                <div class="row">
                                    <!-- <div class="col">
                                        <input type="text" class="form-control" placeholder="NRIC" v-model="requiredInfo.nric" @keyup="checkRequiredInfo">
                                    </div> -->
                                    <div class="col" v-if="isPrescriptionNeeded">
                                        <div class="input-group">
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input" id="inputPrescriptionFile" @change="checkRequiredInfo" ref="prescriptionFile">
                                                <label class="custom-file-label" for="inputPrescriptionFile">{{ requiredInfo.prescriptionFileName? requiredInfo.prescriptionFileName : 'Prescription file' }}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <h5>Delivery Method</h5>
                            <div class="mt-2 content-box">
                                <div class="content-box__row" v-if="deliveryTypeShip">
                                    <label class="form-check-container">
                                        <span>
                                            <svg aria-hidden="true" focusable="false" class="icon-svg icon-svg--size-18 icon-svg--inline-before"> 
                                                <use xlink:href="#ship">
                                                    <svg id="ship">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                            <path d="M17.816 14c-.415-1.162-1.514-2-2.816-2-1.302 0-2.4.838-2.816 2H12v-4h6v4h-.184zM15 16c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM5 16c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zM2 4h8v10H7.816C7.4 12.838 6.302 12 5 12c-1.302 0-2.4.838-2.816 2H2V4zm13.434 1l1.8 3H12V5h3.434zm4.424 3.485l-3-5C16.678 3.185 16.35 3 16 3h-4c0-.552-.448-1-1-1H1c-.552 0-1 .448-1 1v12c0 .552.448 1 1 1h1.185C2.6 17.162 3.698 18 5 18s2.4-.838 2.816-2h4.37c.413 1.162 1.512 2 2.814 2s2.4-.838 2.816-2H19c.552 0 1-.448 1-1V9c0-.18-.05-.36-.142-.515z" fill-rule="evenodd">
                                                            </path>
                                                        </svg>
                                                    </svg>
                                                </use>
                                            </svg>
                                            Ship
                                        </span>
                                        <input class="form-check-input" type="radio" name="deliveryMethod" id="deliveryMethod1" value="ship" v-model="deliveryOption.type" @change="calculateAll">
                                        <span class="checkmark"></span>
                                    </label>
                                    <ul v-if="deliveryOption.type == 'ship'">
                                        <li v-for="item in deliveryTypeShip" :key="item.id">
                                            <label class="form-check-container">
                                                <span>{{ item.name }}</span>
                                                <input class="form-check-input" type="radio" name="deliveryMethod1Option" id="deliveryMethod1Option1" :value="item.id" v-model="deliveryOption.shipOptionType" @change="calculateAll">
                                                <span class="checkmark"></span>
                                                <div v-if="showExpressDeliveryInfo(item)" class="small-delivery-info">
                                                    You need to bear the cost of express delivery charges based on the selected courier services.
                                                </div>
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div class="content-box__row border" v-if="deliveryTypePickup && mode == 'default'">
                                    <label class="form-check-container">
                                        <span>
                                            <svg aria-hidden="true" focusable="false" class="icon-svg icon-svg--size-18 icon-svg--inline-before">
                                                <use xlink:href="#pickup">
                                                    <svg id="pickup">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
                                                            <path d="M14.6 16H3.4c-.442 0-.8-.358-.8-.8V7.554C1.648 7 1 5.98 1 4.8V4c0-.12.032-.232.08-.336L2.684.442C2.82.172 3.096 0 3.4 0h11.2c.304 0 .58.17.716.442 0 0 1.602 3.214 1.604 3.222.048.104.08.216.08.336v.8c0 1.18-.648 2.2-1.6 2.754V15.2c0 .442-.358.8-.8.8zm-.8-9.6c.882 0 1.6-.718 1.6-1.6h-3.2c0 .882.718 1.6 1.6 1.6zm0 8V8c-.954 0-1.814-.42-2.4-1.086C10.814 7.58 9.954 8 9 8s-1.814-.42-2.4-1.086C6.014 7.58 5.154 8 4.2 8v6.4h1.6v-4c0-.442.358-.8.8-.8h4.8c.442 0 .8.358.8.8v4h1.6zM2.6 4.8c0 .882.718 1.6 1.6 1.6.882 0 1.6-.718 1.6-1.6H2.6zm8 0H7.4c0 .882.718 1.6 1.6 1.6.882 0 1.6-.718 1.6-1.6zM3.894 1.6l-.8 1.6h11.812l-.8-1.6H3.894zM7.4 14.4h3.2v-3.2H7.4v3.2z">
                                                            </path>
                                                        </svg>
                                                    </svg>
                                                </use>
                                            </svg>
                                            Pick up
                                        </span>
                                        <input class="form-check-input" type="radio" name="deliveryMethod" id="deliveryMethod2" value="pickup" v-model="deliveryOption.type" @change="calculateAll">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <h5> {{ deliveryOption.type == 'ship' ? 'Shipping address' : 'Pickup location' }}</h5>
                            <div v-if="currentCustomer && deliveryOption.type == 'ship' && !isToggleChangeShipAddress" class="mt-2 content-box">
                                <div class="content-box__row row">
                                    <div class="col-sm-10">
                                        <p v-if="deliveryOption.selectedShipAddress">
                                            <b>{{ getSelectedShippingAddressItem('name') }}</b> <br />
                                            {{ getSelectedShippingAddressItem('address') }} <br />
                                            {{ getSelectedShippingAddressItem('phone') }}
                                        </p>
                                        <p v-else>
                                            Sorry, you do not have create any address yet. <router-link to="/customer/addresses">Click here to create one</router-link>.
                                        </p>
                                    </div>
                                    <div v-if="deliveryOption.selectedShipAddress" class="col-sm-2 text-right">
                                        <span class="action" @click="isToggleChangeShipAddress = !isToggleChangeShipAddress">change</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="currentCustomer && deliveryOption.type == 'ship' && isToggleChangeShipAddress" class="mt-2 content-box">
                                <div class="content-box__row" v-for="(address, ind) in customerShippingAddresses" :key="address.id">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label class="form-check-container">
                                                <span>{{ address.firstname }} {{ address.lastname }}</span>
                                                <p>{{ address.address1 }}, {{ address.address2 }}, {{ address.city }}, {{ address.province }}</p>
                                                <input class="form-check-input" type="radio" name="deliveryShip" id="deliveryShip" :value="address" v-model="deliveryOption.selectedShipAddress">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-sm-5 text-right" v-if="ind == 0">
                                            <span class="action" @click="isToggleChangeShipAddress = !isToggleChangeShipAddress">Confirm</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="!currentCustomer && deliveryOption.type == 'ship'">
                                <div class="row mt-3">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="First name" v-model="guestData.address.firstname" @keyup="checkRequiredInfo">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Last name" v-model="guestData.address.lastname" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Address 1" v-model="guestData.address.address1" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Address 2" v-model="guestData.address.address2" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Zipcode" v-model="guestData.address.zipcode" @keyup="checkRequiredInfo">
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="City" v-model="guestData.address.city" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col">
                                        <select class="form-control" v-model="guestData.address.province" required>
                                            <option :value="null" disabled>-Select Province-</option>
                                            <option v-for="item in provinces" :key="item.id" :value="item">{{ item }}</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input type="text" class="form-control" placeholder="Phone Number" v-model="guestData.address.phone" @keyup="checkRequiredInfo">
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="deliveryOption.type == 'pickup'" class="mt-2 content-box">
                                <div class="content-box__row" v-for="address in deliveryTypePickup.delivery_addresses" :key="address.id">
                                    <div class="row">
                                        <div class="col-sm-7">
                                            <label class="form-check-container">
                                                <span>{{ address.location_name }}</span>
                                                <p>{{ address.address1 }}, {{ address.address2 }}, {{ address.city }}, {{ address.province }}</p>
                                                <input class="form-check-input" type="radio" name="deliveryPickup" id="deliveryPickup" :value="address" v-model="deliveryOption.selectedPickupAddress">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <!-- <div class="col-sm-5 text-right">
                                            <span class="price font-weight-bold">Free</span>
                                            <p>Usually ready in 24 hours</p>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="total > 0.00" class="mt-4">
                            <h5>Payment</h5>
                            <p>All transaction are secured and encrypted.</p>
                            <div class="content-box">
                                <div class="content-box__row">
                                    <label class="form-check-container">
                                        <img src="/img/selangkah_pay_logo2.png" alt="" style="max-width: 130px;">
                                        <!-- <span>KipleBiz</span> -->
                                        <input class="form-check-input" type="radio" name="paymentMethod" id="paymentMethod1" value="billplz" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 footer">
                            <router-link to="/store/cart">&lt; Return to cart</router-link>
                            <button v-if="!checkoutBtnProcessing && enablePayButton" class="btn" @click="startPay">{{ total > 0.00 ? 'Pay' : 'Submit Order' }}</button>
                            <button v-else-if="!checkoutBtnProcessing && !enablePayButton" class="btn" disabled>{{ total > 0.00 ? 'Pay' : 'Submit Order' }}</button>
                            <button v-else class="btn" disabled>
                                <span class="spinner-border spinner-border-sm"></span>
                                Processing..
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 full-gray">
                    <aside class="side-info" v-if="cart">
                        <div class="items-box">
                            <div class="row" v-for="item in cart.items" :key="item.id">
                                <div class="col-sm-2">
                                    <div class="img-product">
                                        <img :src="getImageLink(item.product)" alt="">
                                        <div class="quantity">
                                            {{ item.quantity }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 title-product">
                                    <span>{{ item.product.title }}</span>
                                </div>
                                <div class="col-sm-4 subtotal-product">
                                    <span v-if="!isVIP">RM {{ (item.quantity * getProdPrice(item.product)).toFixed(2) }}</span>
                                    <span v-else>RM 0.00</span>
                                </div>
                            </div>
                        </div>
                        <div class="discount-box">
                            <div class="row">
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="discountCode" placeholder="Discount code" v-model="discountCode">
                                </div>
                                <button v-if="discountCode" class="col-sm-3 btn" @click="applyDiscount()">Apply</button>
                                <button v-else class="col-sm-3 btn" disabled>Apply</button>
                            </div>
                            <div class="row" v-if="disountCodeError">
                                <div class="col ml-1 mt-1" style="color:#f63f3d;font-style:italic;">
                                    {{ disountCodeError }}
                                </div>
                            </div>
                        </div>
                        <div class="calculation-box">
                            <div class="mt-2">
                                <span>Subtotal</span>
                                <span class="float-right amount">RM {{ subtotal.toFixed(2) }}</span>
                            </div>
                            <div v-if="mode == 'default'" class="mt-2">
                                <span>Shipping</span>
                                <span v-if="shippingValue.toFixed(2) != 0.00" class="float-right amount">RM {{ shippingValue.toFixed(2) }}</span>
                                <span v-else class="float-right amount">Free</span>
                            </div>
                            <div v-else-if="mode == 'e-prescription'" class="mt-2">
                                <span>Shipping</span>
                                <span v-if="isDoneSpecialCalc" class="float-right amount">RM {{ shippingValue.toFixed(2) }}</span>
                                <span v-else class="float-right amount">-</span>
                            </div>
                            <div class="mt-2">
                                <span>Taxes (estimated)</span>
                                <span v-if="mode == 'e-prescription' && !isDoneSpecialCalc" class="float-right amount">-</span>
                                <span v-else class="float-right amount">RM {{ taxValue }}</span>
                            </div>
                            <div class="mt-2" v-if="totalDiscount > 0.00">
                                <span>Discount ({{ discountCodeApplied }})</span>
                                <span class="float-right amount">-RM {{ totalDiscount.toFixed(2) }}</span>
                            </div>
                        </div>
                        <div class="total-box">
                            <div>
                                <span>Total</span>
                                <span v-if="mode == 'e-prescription' && !isDoneSpecialCalc" class="float-right amount">-</span>
                                <span v-else class="float-right amount">RM {{ total.toFixed(2) }}</span>
                            </div>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
        <!-- Checkout Processing Overlay -->
        <div v-if="checkoutOverlay" class="processing-overlay">
            <div v-if="checkoutProcessing" class="processing">
                <div class="spinner-border spinner-border-sm"></div>
                <span> Processing payment ...</span>
            </div>
            <div v-if="!checkoutProcessing && checkoutSuccess" class="processing">
                <span> Done. Success.</span>
            </div>
            <div v-if="!checkoutProcessing && !checkoutSuccess && checkoutError" class="processing">
                <span> Done with failure.</span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import API from '../../../utils/API'
import { checkProductPromo } from './../../../utils/ProductUtils'

export default {
    data(){
        return {
            mode: 'default', //default or e-prescription
            isDoneSpecialCalc: false,
            ePrescription: null,
            jovianDraftOrder: null,
            isVIP: false,
            guestData: {
                isGuest: false,
                email: null,
                address: {
                    firstname: null,
                    lastname: null,
                    address1: null,
                    address2: null,
                    city: null,
                    zipcode: null,
                    province: null,
                    country: 'Malaysia', //default for Malaysia right now
                    phone: null
                }
            },
            isPrescriptionNeeded: false,
            requiredInfo: {
                nric: null,
                prescriptionFileName: null
            },
            deliveryTypeShip: null,
            deliveryTypePickup: null,
            customerShippingAddresses: [],
            deliveryOption: {
                type: 'ship', //selected delivery method. ship or pickup or cod (soon). Default is ship
                shipOptionType: null, //id of delivery type (especially for ship type)
                selectedShipAddress: null, //obj of selected shipping address
                selectedPickupAddress: null // obj of selected pickup address,
            },
            isToggleChangeShipAddress: false,
            enablePayButton: false,
            discountCode: null,
            discountCodeApplied: null,
            discountCodeValue: null,
            disountCodeError: null,
            subtotal: 0.00,
            shippingValue: 0.00,
            taxValue: 0.00,
            totalDiscount: 0.00,
            total: 0.00,
            testmode: false, //for now is testing. Make it turn on off at admin.
            provinces: [
                'Johor',
                'Kedah',
                'Kelantan',
                'Kuala Lumpur',
                'Labuan',
                'Melaka',
                'Negeri Sembilan',
                'Pahang',
                'Penang',
                'Perak',
                'Perlis',
                'Putrajaya',
                'Sabah',
                'Sarawak',
                'Selangor',
                'Terengganu'
            ]
        }
    },
    computed: {
        ...mapGetters(['cart', 'currentCustomer', 'checkoutProcessing', 'checkoutBtnProcessing', 'checkoutOverlay', 'checkoutSuccess', 'checkoutError']),
    },
    methods: {
        ...mapActions(['createPayment', 'signOut']),
        scrollTop(){
            window.scrollTo(0,0)
        },
        showExpressDeliveryInfo(_item){
            let itemFound = this.deliveryTypeShip.find(i => i.id == this.deliveryOption.shipOptionType)
            if(itemFound.name.includes('Express') && _item.name.includes('Express'))
                return true
            else
                return false
        },
        initPromo(){
            this.cart.items.forEach(item => {
                let prodPromo = checkProductPromo(item.product)
                if(prodPromo) { //craete new subproperties if promo exist
                    item.product.promo_price = prodPromo.promo_price
                    // item.product.promo_badge_url = prodPromo.promo_badge
                }
            })
        },
        getProdPrice(_product){
            if(_product.promo_price) return _product.promo_price
            else return _product.variants[0].price
        },
        getImageLink(_product) {
            if(!_product.variants[0].image || _product.variants[0].image.length == 0) return '/img/default_no_image.jpg'
            else return API.getAPIUrl() + _product.variants[0].image[0].url //get the first image
        },
        checkPrescriptionRequirement(){
            this.cart.items.forEach(item => {
                if(item.product.is_prescription_needed)
                    this.isPrescriptionNeeded = true
            })
        },
        checkRequiredInfo(){
            if(this.isPrescriptionNeeded && this.$refs.prescriptionFile){
                if(this.$refs.prescriptionFile.files[0]) 
                    this.requiredInfo.prescriptionFileName = this.$refs.prescriptionFile.files[0].name
            }
            else if(this.isPrescriptionNeeded && typeof this.$refs.prescriptionFile == 'undefined') this.requiredInfo.prescriptionFileName = null
            else //if no need prescription
                this.requiredInfo.prescriptionFileName = 'no_need_prescription_file'
            
            if(this.guestData.isGuest){
                if(this.deliveryOption.type == 'ship'){
                    if(this.requiredInfo.prescriptionFileName && this.guestData.email != null && this.guestData.email != ''
                        && this.guestData.address.firstname != null && this.guestData.address.firstname != '' && this.guestData.address.lastname != null && this.guestData.address.lastname != ''
                        && this.guestData.address.address1 != null && this.guestData.address.address1 != '' && this.guestData.address.address2 != null && this.guestData.address.address2 != ''
                        && this.guestData.address.city != null && this.guestData.address.city != '' && this.guestData.address.zipcode != null && this.guestData.address.zipcode != ''
                        && this.guestData.address.province != null && this.guestData.address.province != '' && this.guestData.address.country != null && this.guestData.address.country != ''
                        && this.guestData.address.phone != null && this.guestData.address.phone != '') 
                        this.enablePayButton = true
                    else this.enablePayButton = false
                }else if(this.deliveryOption.type == 'pickup'){
                    if(this.requiredInfo.prescriptionFileName && this.guestData.email != null && this.guestData.email != '') 
                        this.enablePayButton = true
                    else this.enablePayButton = false
                }
            }else {
                if(this.requiredInfo.prescriptionFileName) this.enablePayButton = true
                else this.enablePayButton = false
                //if no address yet
                if(this.deliveryOption.type == 'ship' && this.currentCustomer.user.customer.shipping_addresses.length == 0) this.enablePayButton = false
            }
        },
        async initDeliverySetting(){
            let res01 = await API.get('/delivery-services')
            this.deliveryTypeShip = res01.data.filter(item => item.is_active == true && item.type == 'ship') //get ship delivery
            if(this.mode == 'e-prescription') 
                this.deliveryTypeShip = this.deliveryTypeShip.filter(item => item.name.includes('Standard'))

            this.deliveryTypePickup = res01.data.find(item => item.is_active == true && item.type == 'pickup') //get pickup delivery
            //get cod delivery...not yet
            this.deliveryOption.shipOptionType = this.deliveryTypeShip[0].id
            this.deliveryOption.selectedPickupAddress = this.deliveryTypePickup.delivery_addresses[0]

            //init default shipping address
            if(this.currentCustomer && this.currentCustomer.user.customer.shipping_addresses.length > 0){
                this.customerShippingAddresses = this.currentCustomer.user.customer.shipping_addresses
                
                this.deliveryOption.selectedShipAddress = this.customerShippingAddresses.find(item => item.id == this.currentCustomer.user.customer.default_shipping_address_id)
            }
        },
        getSelectedShippingAddressItem(_item){
            if(_item == 'name') return this.deliveryOption.selectedShipAddress.firstname + ' ' + this.deliveryOption.selectedShipAddress.lastname
            else if(_item == 'address') return this.deliveryOption.selectedShipAddress.address1 + ', ' + this.deliveryOption.selectedShipAddress.address2 + ', ' + this.deliveryOption.selectedShipAddress.city + ', ' + this.deliveryOption.selectedShipAddress.province
            else if(_item == 'phone') return this.deliveryOption.selectedShipAddress.phone

            return ''
        },
        calculateAll(){
            //calculate subtotal
            let val = 0.00
            this.cart.items.forEach(item => {
                val += parseFloat((item.quantity * this.getProdPrice(item.product)).toFixed(2))
            })
            this.subtotal = val

            //calculate shipping
            if(this.subtotal >= 150.00 || this.deliveryOption.type == 'pickup') this.shippingValue = 0.00
            else if(this.deliveryOption.type == 'ship') {
                this.deliveryTypeShip.forEach(item => {
                    if(item.id == this.deliveryOption.shipOptionType)
                        this.shippingValue = item.fee_charge
                })
            }

            //calculate tax
            this.taxValue = parseFloat(this.subtotal * 0.06).toFixed(2)

            //verify for discount
            let newSubtotal = 0.00
            if(this.discountCode && this.discountCodeValue > 0.00){
                this.totalDiscount = this.discountCodeValue
                newSubtotal = parseFloat(this.subtotal) - parseFloat(this.totalDiscount)
            } else newSubtotal = this.subtotal
            if(newSubtotal < 0.00) newSubtotal = 0.00

            //If customer type is vip then total is different
            if(this.isVIP){
                newSubtotal = 0.00
                this.subtotal = 0.00
                this.taxValue = 0.00
            }

            //calculate total
            this.total = parseFloat(newSubtotal) + parseFloat(this.shippingValue) + parseFloat(this.taxValue)

            //recheck the information
            this.checkRequiredInfo()
        },
        async applyDiscount(){
            this.disountCodeError = null

            let data2send = {
                discount_code : this.discountCode,
                items: this.cart.items,
                customer: this.currentCustomer? this.currentCustomer.user.customer : null
            }
            let resDiscount = await API.post(`/discounts/validate-discount`, data2send)
            if(!resDiscount.data.errorMsg){
                this.discountCodeValue = resDiscount.data.total_discounted
                this.discountCodeApplied = resDiscount.data.discount_code
                this.calculateAll()
            }
            else {
                this.disountCodeError = resDiscount.data.errorMsg
            }
        },
        getPaymentData(){
            //a bit setting for address
            let deliveryAddress = null
            let deliveryTypeId = null
            if(this.deliveryOption.type == 'ship'){
                deliveryTypeId = this.deliveryOption.shipOptionType
                deliveryAddress = {
                    firstname: this.currentCustomer? this.deliveryOption.selectedShipAddress.firstname : this.guestData.address.firstname,
                    lastname: this.currentCustomer? this.deliveryOption.selectedShipAddress.lastname : this.guestData.address.lastname,
                    company: this.currentCustomer? this.deliveryOption.selectedShipAddress.company : '-',
                    address1: this.currentCustomer? this.deliveryOption.selectedShipAddress.address1 : this.guestData.address.address1,
                    address2: this.currentCustomer? this.deliveryOption.selectedShipAddress.address2 : this.guestData.address.address2,
                    city: this.currentCustomer? this.deliveryOption.selectedShipAddress.city : this.guestData.address.city,
                    zipcode: this.currentCustomer? this.deliveryOption.selectedShipAddress.zipcode : this.guestData.address.zipcode,
                    province: this.currentCustomer? this.deliveryOption.selectedShipAddress.province : this.guestData.address.province,
                    country: this.currentCustomer? this.deliveryOption.selectedShipAddress.country : this.guestData.address.country,
                    phone: this.currentCustomer? this.deliveryOption.selectedShipAddress.phone : this.guestData.address.phone
                }
            } else if(this.deliveryOption.type == 'pickup'){
                deliveryTypeId = this.deliveryTypePickup.id
                deliveryAddress = {
                    firstname: this.currentCustomer? this.deliveryOption.selectedShipAddress.firstname : this.guestData.address.firstname,
                    lastname: this.currentCustomer? this.deliveryOption.selectedShipAddress.lastname : this.guestData.address.lastname,
                    phone: this.currentCustomer? this.deliveryOption.selectedShipAddress.phone : this.guestData.address.phone,
                    
                    location_name: this.deliveryOption.selectedPickupAddress.location_name,
                    address1: this.deliveryOption.selectedPickupAddress.address1,
                    address2: this.deliveryOption.selectedPickupAddress.address2,
                    city: this.deliveryOption.selectedPickupAddress.city,
                    zipcode: this.deliveryOption.selectedPickupAddress.zipcode,
                    province: this.deliveryOption.selectedPickupAddress.province,
                    country: this.deliveryOption.selectedPickupAddress.country,
                    // phone: this.deliveryOption.selectedPickupAddress.phone
                }
            }
        
            return {
                description: this.currentCustomer ? `${this.currentCustomer.user.email} - Selcare` : `${this.guestData.address.firstname} ${this.guestData.address.lastname} - Selcare`,
                email: this.currentCustomer ? this.currentCustomer.user.email : this.guestData.email,
                phone: this.currentCustomer ? this.currentCustomer.user.phone : this.guestData.address.phone,
                name: this.currentCustomer ? this.currentCustomer.user.username : `${this.guestData.address.firstname} ${this.guestData.address.lastname}`,
                isGuest: this.guestData.isGuest,
                hasPrescription: this.isPrescriptionNeeded,
                prescriptionFile: this.isPrescriptionNeeded? this.$refs.prescriptionFile.files[0] : null,
                nric: this.requiredInfo.nric,
                testmode: this.testmode,
                total: this.total,
                subtotal: this.subtotal,
                shippingValue: this.shippingValue,
                taxValue: this.taxValue,
                totalDiscount: this.totalDiscount,
                customerId: this.currentCustomer? this.currentCustomer.user.customer.id : null,
                items: this.cart.items,
                deliveryTypeId: deliveryTypeId,
                deliveryAddress: deliveryAddress,
                cart: this.cart,
                jovianselcareDraftOrderID: this.jovianDraftOrder ? this.jovianDraftOrder.id : null
            }
        },
        startPay(){
            this.createPayment(this.getPaymentData())
        },
        initEPrescriptionCheckout(){
            //1. First execute BE init draftorder & to get final total + shipping fee + shipping type
            //2. Start to use it and pay
            //3. update jovianselcare draftorder. 
            return new Promise(async(resolve, reject) => {
                //ready the payload
                const payload = {
                    inputData : {
                        address: {
                            address1 : this.deliveryOption.selectedShipAddress.address1,
                            address2 : this.deliveryOption.selectedShipAddress.address2,
                            city : this.deliveryOption.selectedShipAddress.city,
                            company : "-",
                            country : this.deliveryOption.selectedShipAddress.country,
                            countryCode : "MY", //default?
                            firstName : this.deliveryOption.selectedShipAddress.firstname,
                            lastName : this.deliveryOption.selectedShipAddress.lastname,
                            // phone : (this.deliveryOption.selectedShipAddress.phone).includes('+6') ? this.deliveryOption.selectedShipAddress.phone : '+6'+this.deliveryOption.selectedShipAddress.phone,
                            phone: "+60179644197", //harcoded just for now
                            province : this.deliveryOption.selectedShipAddress.province,
                            provinceCode : "14", //nanti buat dynamic (at) --tapi kene tanye malik
                            zip : this.deliveryOption.selectedShipAddress.zipcode
                        },
                        email: this.currentCustomer.user.email,
                        lineItems: [],
                        poNumber: (this.ePrescription.prescription_name).includes('#') ? (this.ePrescription.prescription_name).replace('#', '') : this.ePrescription.prescription_name,
                        note: `Checkout e-prescription of ${this.ePrescription.prescription_name}`,
                        // phone: (this.deliveryOption.selectedShipAddress.phone).includes('+6') ? this.deliveryOption.selectedShipAddress.phone : '+6'+this.deliveryOption.selectedShipAddress.phone
                        phone: "+60179644197", //harcoded just for now,
                        ePrescriptionName: this.ePrescription.prescription_name,
                        ePrescriptionID: this.ePrescription.id
                    }
                }
                
                this.cart.items.forEach(item => {
                    payload.inputData.lineItems.push({
                        quantity: item.quantity,
                        title: item.product.title,
                        variantId: item.product.variants[0].jovianselcare_variant_id
                    })
                })

                //start submit for draft order
                let res01 = await API.post('/custom/jovianselcare-create-draftorder', payload)
                this.jovianDraftOrder = res01.data.draftOrder.draftOrder
                // console.log(this.jovianDraftOrder)

                //fill the shipping & total
                this.shippingValue = parseFloat(this.jovianDraftOrder.shippingLine.originalPriceSet.presentmentMoney.amount)
                this.total = parseFloat(this.jovianDraftOrder.totalPrice)

                //done init
                this.isDoneSpecialCalc = true
                resolve(0)
            })
        }
    },
    async created(){
        //if for prescription
        if(this.$route.query.mode && this.$route.query.mode == 'e-prescription'){
            this.mode = 'e-prescription'
        } 
        //if default
        else {
            // this.cartItems = this.cart.items.filter(i => !i.product.is_jovianselcare_prod || i.product.is_jovianselcare_prod == false)
            this.mode = 'default'
        }
    },
    async mounted(){
        this.scrollTop()

        await this.initDeliverySetting() //init delivery setting

        if(!this.currentCustomer) this.guestData.isGuest = true //set isguest to true if currentcustomer doesnt exist
        //check prescription requirement
        this.checkPrescriptionRequirement()

        //Set all price to 0.00 if customer type == vip
        if(this.currentCustomer){
            if(this.currentCustomer.user.customer.cust_type == 'vip') this.isVIP = true
            else this.isVIP = false
        }

        this.initPromo()

        //init calculation.
        this.calculateAll()

        //if e-prescription checkout
        if(this.mode == 'e-prescription'){
            const res = await API.get('/prescriptions/'+ this.$route.query['e-prescription'])
            this.ePrescription = res.data

            //start init
            await this.initEPrescriptionCheckout()
        }
    }
}
</script>

<style scoped>
/* #checkout {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
} */
.h5, h5 {
    font-size: 1.1rem;
}
.form-control {
    font-size: 14px;
    /* padding: 20px 15px; */
    height: 43px;
}

/** Custom Container here
    Need to create media queries also.
 */
.custom-container .main-info {
    max-width: 670px;
    margin-left: auto;
    padding-right: 40px;
}
.custom-container .full-gray {
    background-color: #fafafa;
    /* height: calc(100vh - 144px); */
    height: 100vh;
    box-shadow: 1px 0 0 #e1e1e1 inset;
}
.custom-container .side-info {
    max-width: 400px;
    margin-right: auto;
}

.processing-overlay {
    position: absolute;
    top: 1px;
    width: 100%;
    height: calc(100vh + 127px);
    background: rgb(255 255 255 / 75%);
    text-align: center;
    padding: 300px 0px;
}

.small-info small {
    color: #545454;
}
.small-info a {
    color: #a91a18;
}


.main-info {
    padding: 15px 20px;
}

.side-info {
    padding: 10px 20px 15px 15px;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif; */
    line-height: 1.3em;
    font-size: 14px;
}

nav {
    text-transform: capitalize;
    font-weight: bold;
}
nav ol.breadcrumb {
    background-color: unset;
    padding: 12px 0px;
}
nav li.breadcrumb-item+.breadcrumb-item::before {
    content: ">";
}
nav a {
    color: #a91a18;
}

.main-info h5 {
    line-height: 1.5em;
    font-weight: normal;
    color: #333333;
}

.main-info .form-check-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default radio button */
.main-info .form-check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
/* Create a custom radio button */
.main-info .form-check-container .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 21px;
    width: 21px;
    background-color: #eee;
    border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.main-info .form-check-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the radio button is checked, add a blue background */
.main-info .form-check-container input:checked ~ .checkmark {
  background-color: #a91a18;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.main-info .form-check-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (dot/circle) when checked */
.main-info .form-check-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the indicator (dot/circle) */
.main-info .form-check-container .checkmark:after {
    top: 8px;
    left: 8px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: white;
}
.main-info .form-check-container span svg {
    width: 17px;
    height: 17px;
    margin-right: 4px;
}

.img-profile {
    display: inline-block;
    /* background-color: #d8d8d8; */
    border-radius: 10px;
    margin-right: 15px;
    width: 58px;
}
.img-profile img {
    width: 100%;
}
.info-profile {
    display: inline-block;
    vertical-align: middle;
}
.info-profile p {
    margin-bottom: 0px;
}
.info-profile a {
    padding: 0px;
    color: #a91a18;
}

.content-box {
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    font-size: 14px;
}
.content-box .content-box__row {
    padding: 10px 15px;
}
.content-box .content-box__row.border {
    border-top: 1px solid #d9d9d9!important;
    border-bottom: unset!important;
    border-left: unset!important;
    border-right: unset!important;
}
.content-box .content-box__row p {
    margin: 0px;
}
.content-box .content-box__row ul {
    padding-left: 20px;
    margin-bottom: 0px;
}
.content-box .content-box__row li {
    display: block;
    margin: 10px 0px;
}
.content-box .content-box__row .action {
    color: #a91a18;
}
.content-box .content-box__row .action:hover {
    text-decoration: underline;
    cursor: pointer;
}


.main-info .footer a {
    margin-top: 13px;
    display: inline-block;
    color: #a91a18;
}
.main-info .footer .btn {
    float: right;
    background-color: #a91a18;
    color: #fff;
    padding: 10px 50px;
}

.side-info .items-box {
    padding: 15px 0px;
}
.side-info .items-box .img-product {
    width: 150%;
    margin-bottom: 15px;
}
.side-info .items-box .img-product .quantity {
    border-radius: 11px;
    background-color: rgba(114,114,114,0.9);
    color: #fff;
    position: absolute;
    width: 20px;
    padding: 1px 7px;
    font-size: 12px;
    font-weight: 500;
    top: -8px;
    right: -16px;
}
.side-info .items-box .img-product img {
    width: 100%;
    border-radius: 5px;
}
.side-info .items-box span {
    color: #323232;
    font-weight: 500;
}
.side-info .items-box .title-product {
    max-height: 48px;
    margin-bottom: auto;
    margin-top: auto;
}
.side-info .items-box .subtotal-product {
    max-height: 25px;
    margin-bottom: auto;
    margin-top: auto;
}
.side-info .discount-box {
    border-top: 1px solid rgba(175,175,175,0.34);
    border-bottom: 1px solid rgba(175,175,175,0.34);
    padding: 15px 0px;
}
.side-info .discount-box .col-sm-9 {
    padding: -1px 10px 0px 0px;
}
.side-info .discount-box .btn {
    background-color: #a91a18;
    color: #fff;
    max-width: 22%;
}
.side-info .calculation-box {
    padding: 15px 0px;
    border-bottom: 1px solid rgba(175,175,175,0.34);
}
.side-info .calculation-box .amount {
    font-weight: 500;
}
.side-info .total-box {
    padding: 15px 0px;
}
.side-info .total-box .amount {
    font-weight: 500;
    font-size: 22px;
}

.small-delivery-info {
    margin-top: 5px;
    background-color: #ffff8a;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Change bootstrap input focus */
select:focus,
textarea:focus,
input[type="text"]:focus,
input[type="file"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  border: 2px solid #a91a18;
  box-shadow: unset;
  /* border-color: #a91a18; */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #a91a18; */
  outline: 0 none;
}

@media (max-width: 575px){
    .side-info .items-box .img-product {
        width: 100%;
    }
    .side-info .discount-box .btn {
        max-width: 92%;
        margin-left: 15px;
        margin-top: 10px;
    }
}

/* .side-info::after{
    content: "";
    display: block;
    width: 300%;
    position: absolute;
    top: 0;
    left: -100%;
    bottom: 0;
    background: #fafafa;
    z-index: -1;
    -webkit-box-shadow: 0 -1px 0 #e1e1e1 inset;
    box-shadow: 0 -1px 0 #e1e1e1 inset;
}
@media (min-width: 1000px){
    .side-info::after {
        left: 0;
        background-position: left top;
        -webkit-box-shadow: 1px 0 0 #e1e1e1 inset;
        box-shadow: 1px 0 0 #e1e1e1 inset;
    }
} */
</style>